<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Товар" prop="product_id">
						<SimpleSpinner :active="productsLoading" small />
						<el-select
							v-model="formData.product_id"
							filterable
							placeholder="выберите товар"
							:disabled="!productsList.length"
						>
							<el-option
								v-for="item in productsList"
								:key="'product_id-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Рейтинг" prop="rating">
						<el-input-number
							v-model="formData.rating"
							placeholder="Рейтинг"
							:min="1"
							:max="5"
						/>
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-9">
					<el-form-item label="Отзыв" prop="message">
						<el-input v-model="formData.message" type="textarea" rows="5" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Опубликовано" prop="is_published">
						<el-switch
							v-model="formData.is_published"
							:active-value="1"
							:inactive-value="0"
						/>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin, itemsFetchSetMixin } from '@/mixins';

export default {
	mixins: [itemFormMixin, itemsFetchSetMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				product_id: null,
				is_published: 0,
				message: '',
				rating: 1
			},

			rules: {
				product_id: required,
				message: required,
				rating: required
			}
		};
	},

	computed: {
		...mapState({
			productsList: state => state.products.itemsList,
			productsLoading: state => state.products.isLoading
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_products',
				payload: { params: { max: -1 } },
				clean_up: 'set_products'
			}
		]
	},

	methods: {
		...mapActions({
			fetch_products: 'products/fetch_products',
			set_products: 'products/set_products'
		})
	}
};
</script>
